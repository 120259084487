<script>
export default {
  data() {
  },
  components: {
  },
  methods: {
  }
};
</script>

<template>
  <h4>Умови та правила користування сервісом «BUSYCAST»</h4>
  <p>Ці умови та правила користування сервісом «BUSYCAST» (далі - «BUSYCAST» або «Сервіс», або «Компанія»), який розташований в інтернеті за посиланням https://busycast.com, адресовані фізичною особою-підприємцем Кошовим Дмитром Вячеславовичем, який діє на підставі Свідоцтва, особам (далі — «Користувач», «Автор», «Підписник»), які користуються Сервісом, та є офіційною публічною пропозицією відповідно до статей 633, 634, 641 Цивільного кодексу України. Ці правила регулюють взаємовідносини між Компанією та Користувачем, що спрямовані на забезпечення потреб Користувача у доступі та користуванні Сервісом.</p>
  <p>Перед тим, як скористатись Сервісом, будь ласка, уважно ознайомтеся з умовами публічного договору (далі - «Договору», «Умови», «Умови та правила»), викладеними нижче.</p>

  <h5>1. Опис термінів.</h5>
  <p>Акцепт — повна й безумовна згода Користувача з усіма положеннями цього Договору та додатків до нього (за наявності) без застережень та винятків. Акцептом вважається факт використання Користувачем Сервісу, зокрема реєстрація Користувача у Сервісі, заповнення будь-яких форм у Сервісі, відправлення повідомлень, запитів на електронну пошту, вказану у Сервісі, залишення відгуків у Сервісі або вчинення будь-яких інших дій з використанням функціональних можливостей Сервісу, в тому числі після внесення будь-яких змін до цього Договору та/або додатків до нього (за наявності).</p>
  <p>Користувач — фізична або юридична особа, які добровільно користується Сервісом.</p>
  <p>Автор — Користувач, який за допомогою інструментів Сервісу має можливість отримувати матеріальну підтримку своєї творчості, пропонуючи іншим Користувачам споживання текстової і/або аудіо-візуальної інформації (далі — «Контент Автора»), поширеної в електронному вигляді за допомогою інших онлайн-сервісів та платформ, а також має обліковий запис в Сервісі.</p>
  <p>Підписник — користувач Сервісу, який має можливість, за власним бажанням, матеріально підтримати Автора або оплатити Авторові за споживання Контенту Автора.</p>
  <p>Донат — добровільна пожертва коштів Авторові або оплата за споживання Контенту Автора.</p>
  <p>Регулярний донат — донат, який Підписник оплачує Авторові на регулярній основі.</p>
  <p>Комісія — сума коштів, яку сервіс стягує з Автора за здійснення операції з переказу грошових коштів відповідно до Договору, укладеного між Автором та Сервісом.</p>
  <p>Контент - будь-які повідомлення, зображення, анімація, звуки, інші дані та інформація, яку Автор або Підписник завантажує на Сервіс або з використанням функціональних можливостей Сервісу.</p>
  <p>Fondy — це платіжний сервіс, створений для великих та малих бізнесів, призначений для проведення розрахунків між фізичними особами, юридичними особами та/або фізичними особами-підприємцями в мережі Інтернет за допомогою персональних комп'ютерів та/або інших мобільних пристроїв.</p>
  
  <h5>2. Реєстрація.</h5>
  <p>Реєстрація - створення Користувачем персонального акаунту шляхом використання.</p>

  <h5>3. Відправка Донатів.</h5>
  <p>3.1. Підписник має можливість надіслати Донат Авторові будь-яким методом оплати у платіжному сервісі Fondy.</p>
  <p>3.2. Комісія платіжного сервісу вираховується з донату Автора. За власною згодою, Підписник може оплатити комісію платіжного сервісу.</p>
  <p>3.3. Оплата за послуги Сервісу становить від 2% до 5% від отриманих донатів.</p>
  <p>3.4. Надсилаючи Донат, Підписник підтверджує, що пожертва коштів Авторові є добровільною, або послуги зі споживання контенту Автора надані Підписникові у повному обсязі.</p>
  <p>3.5. Надсилаючи Донат, Підписник погоджується з тим, що Сервіс не має технічної можливості та не буде здійснювати повернення коштів Донату Підписникові.</p>
  <p>3.6. Надсилаючи Регулярний донат, Підписник погоджується з тим, що Сервіс буде ініціювати періодичне списання коштів через платіжний сервіс, кожного наступного місяця від дня першої оплати. Підписник може припинити наступне списання Регулярного донату у будь-який час.</p>

  <h5>4. Умови використання Платних сервісів</h5>
  <p>4.1. «BUSYCAST» надає Користувачам доступ до певних платних функцій чи контенту в обмін на одноразове або регулярне стягнення плати (кожен із них – "Платний сервіс"). Платні сервіси – це спонсорство, платні підписки, інші послуги «BUSYCAST» тощо.</p>
  <p>4.2. Платні сервіси надаються Компанією. Для керування Платними сервісами зараз і в майбутньому ми можемо використовувати сервіси Fondy.</p>
  <p>4.3. Ваші трансакції та використання вами Платних сервісів регулюються цими Умовами.</p>
  <p>Уважно прочитайте Умови й переконайтеся, що ви їх розумієте. Якщо ви не розумієте Умови або не приймаєте якусь їх частину, то не можете використовувати Платні сервіси. Щоразу, коли ви замовлятимете Платний сервіс (зокрема оформлюватимете підписку або купуватимете контент), то укладатимете з нами окрему угоду для вибраного Платного сервісу й матимете прийняти Умови використання Платних сервісів знову.</p>
  <p>4.4. Платні сервіси й деякий доступний у них контент можуть надаватися не в усіх країнах. Ви погоджуєтеся не надавати неправдиву, неточну або оманливу інформацію з метою видати себе за резидента іншої країни та гарантуєте, що не намагатиметесь обійти обмеження щодо доступу до Платних сервісів або наявного в них контенту.</p>
  <p>4.5. У нашій <router-link to="/pages/privacy-policy" class="text-primary">Політиці конфіденційності</router-link> описано, як ми обробляємо персональні дані та захищаємо конфіденційність, коли ви користуєтеся Платними сервісами.  Уважно прочитайте її.</p>
  <p>Компанія приймає платежі через спосіб оплати сервісів Fondy.</p>
  <p>4.6. Ви погоджуєтеся оплачувати всі замовлені Платні сервіси. «BUSYCAST» стягуватиме з вашого способу оплати вартість вибраного Платного сервісу, а також усі відповідні податки, банківські комісії та різницю, спричинену коливанням курсу валюти.</p>
  <p>4.7. Оформлюючи на Платний сервіс підписку, яка автоматично поновлюється, ви надаєте «BUSYCAST» дозвіл стягувати з указаного вами способу оплати вартість відповідної підписки в перший день кожного платіжного циклу. Якщо цей спосіб оплати стане недійсним у зв’язку із завершенням строку дії кредитної картки чи з іншої причини, а Компанії не вдасться стягнути з вас плату протягом наступного розрахункового періоду, ви не зможете отримати доступ до відповідного Платного сервісу, доки не оновите свій спосіб оплати. У такому разі ми за можливості надішлемо вам відповідне сповіщення. Якщо ви не оновите спосіб оплати впродовж належного періоду часу після цього сповіщення, ми можемо скасувати вашу підписку.</p>
  <p>4.8. Час від часу «BUSYCAST» може пропонувати користувачам безкоштовні пробні версії Платних сервісів. Якщо ви придбаєте підписку на Платний сервіс із пробною версією, то зможете отримати доступ до такого Платного сервісу протягом відповідного пробного періоду. Коли цей період завершиться, з вас автоматично стягуватиметься вартість підписки, доки ви її не скасуєте. Щоб плата не стягувалася, потрібно скасувати підписку до закінчення пробного періоду.</p>
  <p>4.9. Якщо Компанія матиме стягнути або сплатити податки за придбаний вами Платний сервіс, відповідну суму буде списано з вашого способу оплати під час здійснення трансакції.</p>
  <p>4.10. Користувачі, які оформили на Платний сервіс підписку, що поновлюється автоматично, можуть будь-коли скасувати її до завершення поточного розрахункового періоду. Скасування набуде чинності наприкінці такого періоду. З моменту скасування підписки й до початку наступного розрахункового періоду ви зможете й далі користуватися Платним сервісом, однак не отримаєте відшкодування або нарахування за решту днів поточного розрахункового періоду.</p>
  <p>4.11. Здійснивши трансакцію й сплативши відповідні кошти за Платний сервіс, ви можете використовувати його лише для особистих некомерційних потреб, доки дотримуєтеся Умов і вимог законодавства. Ліцензія на Платні сервіси, включно з контентом, що пропонується через них, є невиключною. Усі права, зокрема майнові, на Платні сервіси, які згідно з Умовами не надано вам, залишає за собою Компанія. Ви погоджуєтесь не використовувати Платні сервіси повністю або частково у зв’язку з будь-яким публічним представленням, крім випадків, коли таке використання не є порушенням авторських прав.</p>
  <p>4.12. Під час роботи з Платними сервісами не дозволяється вчиняти (або ж намагатись вчиняти) перелічені нижче дії:</p>
  <p>4.12.1	використовувати Платні сервіси незаконним способом або для досягнення неправомірних цілей;</p>
  <p>4.12.2 ділитися паролем від облікового запису «BUSYCAST» з іншими користувачами, аби надавати їм доступ до Платних сервісів, яких вони не замовляли;</p>
  <p>4.12.3	копіювати, продавати, давати в прокат чи надавати субліцензію на Платні сервіси сторонній особі;</p>
  <p>4.12.4	обходити технології захисту, завдяки яким «BUSYCAST» захищає Платні сервіси, вдаватися до зворотної розробки цих технологій, змінювати й вимикати їх або іншим чином втручатися в їхню роботу, чи заохочувати до цього інших користувачів або допомагати їм у цьому;</p>
  <p>4.13. Ми постійно працюємо над розробкою нових функцій і пошуком способів покращити наші послуги, тому можемо вносити зміни в Платні сервіси. </p>
  <p>4.14. Вартість Платних сервісів може час від часу змінюватися, щоб, наприклад, відображати інфляцію, зміни в цінах з боку ліцензіарів, зміни в самих Платних сервісах і бізнес-потребах, які не стоять на місці. «BUSYCAST» не забезпечує захист цін. Усі зміни цін на Платні сервіси з регулярними платежами застосовуватимуться лише після того, як ми надішлемо вам відповідне сповіщення. Якщо ви отримали таке сповіщення й не захочете далі користуватися Платним сервісом за новою ціною, можете скасувати підписку на нього до початку наступного періоду її дії, у якому застосовуватиметься нова ціна.</p>
  <p>4.15. «BUSYCAST» може змінювати ці Умови, наприклад, (1) щоб відображувати зміни в наших Сервісах або способі ведення бізнесу, зокрема коли ми додаємо нові продукти й функції або вилучаємо старі; (2) з міркувань безпеки або юридичних чи нормативно-правових причин; (3) для запобігання порушенням і шкоді. Якщо ми внесемо істотні зміни в Умови, то надішлемо вам відповідне попереднє сповіщення. Нові Умови використання Платних сервісів набудуть чинності після завершення строку дії сповіщення. Якщо після отримання такого сповіщення ви продовжите користуватися Платними сервісами, це означатиме, що ви приймаєте нові Умови. Нові умови поширюватимуться на використання всього Контенту в Платних сервісах (зокрема, придбаного раніше) і всі подальші покупки. Якщо ви не згодні з такими змінами, то скасуйте придбані підписки до закінчення строку дії сповіщення та  зверніть увагу, що ви не зможете купити додаткові Платні сервіси, а остання прийнята вами версія Умов використання Платних сервісів і далі регулюватиме використання вами раніше придбаних Платних сервісів.</p>
  <p>4.16. Використовуючи Платні сервіси, ви погоджуєтесь отримувати від нас сповіщення, зокрема маркетингові матеріали (такі як інформаційні листи) про функції та контент «BUSYCAST», спеціальні пропозиції, рекламні оголошення, а також опитування для користувачів, на свою зареєстровану електронну адресу чи іншими способами. </p>
  <p>4.17. Щоб захистити обліковий запис «BUSYCAST», не повідомляйте нікому свій пароль і не використовуйте його в програмах сторонніх розробників.</p>

  <h5>5. Контент Користувача</h5>
  <p>5.1. Здійснюючи завантаження Контенту на Сервіс або з використанням функціональних можливостей Сервісу, Користувач гарантує, що:</p>
  <p>5.1.1. Користувач є творцем або законним власником Контенту Користувача чи прав на нього або іншим чином володіє належним обсягом прав і повноважень на Контент Користувача;</p>
  <p>5.1.2. Контент Користувача не буде:</p>
  <ul>
    <li>порушувати законодавство України, міжнародні угоди і конвенції;</li>
    <li>порушувати майнові та немайнові права інтелектуальної власності, включаючи авторські права, права на знаки для товарів і послуг, патенти, комерційну таємницю або інші права інтелектуальної власності чи право власності третіх осіб;</li>
    <li>принижувати честь, гідність, ділову репутацію або іншим чином дискредитувати будь-яку особу;</li>
    <li>вводити будь-яким способом в оману інших Користувачів;</li>
    <li>пропагувати насильство, використання вогнепальної зброї або інших предметів, вилучених чи обмежених в цивільному обороті або описувати чи пропагувати злочинну діяльність, містити інструкції або керівництва з вчинення злочинних дій;</li>
    <li>мати порнографічний або сексуально відвертий характер;</li>
    <li>містити конфіденційну інформацію будь-якої третьої особи, в тому числі персональні дані фізичних осіб;</li>
    <li>містити інформацію про товари та послуги, вироблені у країні, яка здійснює відкрите або приховане протистояння (військове, економічне, юридичне чи інше) Україні, або фінансує терористичні, сепаратистські чи екстемістські організації чи їх окремих представників</li>
  </ul>
  <p>5.1.3. Контент Користувача не містить жодних вірусів, рекламного програмного забезпечення, шпигунського програмного забезпечення або іншого шкідливого програмного коду.</p>
  <p>5.2. Користувач визнає і погоджується з тим, що Сервіс не зобов'язаний контролювати Контент Користувача будь-якого виду, який завантажується на Сервіс або з використанням функціональних можливостей Сервісу, а також те, що Сервіс має право на свій розсуд відмовити Користувачеві в розміщенні та/або розповсюдженні Контенту Користувача або видалити будь-який Контент Користувача в будь-який момент без зазначення причин на розсуд Сервісу та без попереднього повідомлення Користувача. Сервіс не несе відповідальності за збереження Контенту Користувача.</p>
  <p>5.3. Користувач визнає і погоджується з тим, що він зобов’язаний самостійно оцінювати всі ризики, пов'язані з використанням Контенту Користувача, включаючи оцінку законності, надійності, повноти та корисності Контенту Користувача.</p>
  <p>5.4. Користувач гарантує, що не буде використовувати, копіювати, відтворювати, адаптувати, модифікувати, поширювати, відчужувати, іншим чином передавати, публічно демонструвати, виконувати, транслювати, публікувати або іншим чином використовувати Контент Користувача, за винятком випадків, коли він є законним власником такого Контенту Користувача або в інших випадках, визначених цим Договором.</p>
  <p>5.5. Сервіс не несе жодної відповідальності за будь-який Контент Користувача, що завантажується Користувачем на Сервіс або з використанням функціональних можливостей Сервісу, в тому числі за будь-які помилки, заяви, образи, упущення тощо, та за будь-які збитки, шкоду, штрафні санкції, інші додаткові витрати, що виникають чи можуть виникнути у зв'язку з Контентом Користувача.</p>
  <p>5.6. Контент Користувача, розміщений у Сервісі, не відображає позиції Сервісу, крім випадків, коли Сервісом прямо зазначено протилежне.</p>
  <p>5.7. Користувач самостійно несе відповідальність, в тому числі перед третіми особами, за свої дії, пов'язані з використанням Сервісу, включно з випадками порушення прав і законних інтересів третіх осіб, а також за дотримання законодавства України при використанні Сервісу, а у випадках, визначеним цим Договором, - за дотримання законодавства держави юрисдикції Користувача.</p>
  <p>5.8. Сервіс вчиняє дії із захисту прав та інтересів осіб, забезпечення дотримання вимог законодавства України, а у випадках, визначеним цим Договором, - дотримання законодавства держави юрисдикції Користувача, та інших дій, пов'язаних з Контентом, після обґрунтованого звернення зацікавленої особи до Сервісу у спосіб, передбачений цим Договором.</p>
  
  <h5>6. Інтелектуальна власність</h5>
  <p>6.1. Всі використовувані і розміщені у Сервісі об’єкти інтелектуальної власності, в тому числі програмний код, знаки для товарів і послуг, елементи дизайну, текст, графічні зображення, ілюстрації, відео, програмне забезпечення, музика, звуки, інші об'єкти та їх добірки, а також сам Сервіс є об'єктами інтелектуальної власності Сервісу та/або їх законних правовласників та охороняються законодавством України про інтелектуальну власність, а також відповідними міжнародними угодами і конвенціями. Будь-яке використання розміщених на Сервісі об'єктів інтелектуальної власності, у тому числі елементів візуального оформлення Сервісу, символіки, текстів, графічних зображень, ілюстрацій, фото, відео, програм, музики та інших об'єктів, без дозволу Сервісу та/або їх правовласника є незаконним і може стати підставою для судового розгляду та притягнення порушників до цивільно-правової, адміністративної та кримінальної відповідальності відповідно до законодавства України, міжнародних угод та конвенцій.</p>
  <p>6.2. Жоден об'єкт інтелектуальної власності, розміщений у Сервісі, крім тієї інформації, що безпосередньо надсилається Користувачеві при користуванні Сервісом, а також інформації, що вводиться Користувачем особисто, не може бути скопійований (відтворений), перероблений, поширений, опублікований, завантажений, переданий, проданий або іншим способом використаний цілком або в частині (елементах) без попереднього дозволу Сервісу або його законного правовласника.</p>
  <p>6.3. Копіювання та розміщення на інших ресурсах будь-якої інформації з Сервісу у випадку наявності попередньої домовленості з Сервісом має супроводжуватися посиланням на джерело за умови збереження всіх знаків охорони авторського права, суміжних прав, знаків для товарів і послуг, інших повідомлень про авторство, збереження імені (або псевдоніма) автора/найменування правовласника в незмінному вигляді, збереження відповідного об'єкта в незмінному вигляді та не може містити образливої інформації або інформації, що може ввести в оману третіх осіб.</p>
  <p>6.4. Доступ до об'єктів інтелектуальної власності, розміщених у Сервісі, надається виключно для особистого некомерційного використання без права на відтворення, у тому числі без права на копіювання, завантаження, збереження таких об'єктів у пам'ять обладнання Користувача, а також без права на будь-яке інше використання, що не передбачене цим Договором, у тому числі їх продаж, модифікацію, поширення цілком або в частині (елементах) тощо.</p>
  <p>6.5. Інше використання, не передбачене цим Договором, є порушенням умов цього Договору і може стати підставою для притягнення порушника до відповідальності згідно з чинним законодавством України, міжнародними угодами, конвенціями і цим Договором. Будь-яке використання Сервісу або об'єктів інтелектуальної власності, розміщених у ньому, окрім як у спосіб, дозволений цим Договором, категорично заборонено.</p>
  <p>6.6. Будь-які об'єкти інтелектуальної власності, розміщені у Сервісі, можуть бути видалені або змінені без повідомлення про це Користувача.</p>

  <h5>7. Персональні дані Користувача. Конфіденційність.</h5>
  <p>7.1. Політика конфіденційності сервісу «BUSYCAST» викладена окремо в Додатку до цього Договору, який є його невід’ємною частиною </p>

  <h5>8. Відповідальність. Обмеження відповідальності.</h5>
  <p>8.1. Доступ до Сервісу надається за принципом «в тому вигляді, в якому він існує». Сервіс не несе жодної відповідальності, в тому числі за відповідність Сервісу цілям Користувача.</p>
  <p>8.2. Сервіс не гарантує, що:</p>
  <p>8.2.1.	Сервіс відповідає чи відповідатиме вимогам та очікуванням Користувача;</p>
  <p>8.2.2.	Сервіс буде надаватися безперервно, швидко, надійно та без помилок;</p>
  <p>8.2.3.	Якість будь-якої послуги, інформації тощо, отриманих з використанням Сервісу, буде відповідати очікуванням Користувача;</p>
  <p>8.2.4.	Сервіс не містить жодних помилок, упущень, переривань, видалень, описок або інших дефектів;</p>
  <p>8.3. Будь-яку інформацію та/або матеріали, доступ до яких Користувач отримує з використанням Сервісу, Користувач може використовувати на свій власний ризик та самостійно несе відповідальність за можливі наслідки використання зазначеної інформації та/або матеріалів, в тому числі за шкоду, яку таке використання може викликати.</p>
  <p>8.4. За жодних обставин Сервіс не несе відповідальності перед Авторами, Підписниками або третіми особами за будь-які прямі чи непрямі збитки, завдання шкоди честі, гідності або діловій репутації, штрафні санкції, інші додаткові витрати, що виникли у зв'язку з наданням доступу до Сервісу, використанням чи неможливістю використання Сервісу або об'єктів інтелектуальної власності, розміщених у Сервісі, в тому числі, які виникли внаслідок помилок, дій або бездіяльності Сервісу, затримок, видалення Контенту незалежно від причин виникнення таких обставин.</p>
  <p>8.5. Сервіс не несе відповідальності перед Користувачем або будь-якими третіми особами:</p>
  <p>8.5.1.	за дії Користувача у Сервісі;</p>
  <p>8.5.2.	за зміст, достовірність, повноту, законність Контенту;</p>
  <p>8.5.3.	за якість товарів, робіт, послуг, придбаних Підписником після перегляду рекламних заходів Користувача, та їх можливу невідповідність загальноприйнятим стандартам або очікуванням інших Користувачів;</p>
  <p>8.5.4.	за достовірність рекламних заходів Користувача.</p>
  <p>8.6. Сервіс не може контролювати та не контролює дотримання Користувачем умов цього Договору, та не несе жодної відповідальності в разі порушення Користувачем положень цього Договору, вимог законодавства України, а у випадках, визначених цим Договором, - вимог законодавства держави юрисдикції Користувача (залежно від того, що застосовно).</p>
  <p>8.7. Користувач звільняє Сервіс від відповідальності за будь-які збитки, шкоду, штрафні санкції, інші додаткові витрати, включаючи витрати на правову допомогу, які виникли в результаті порушень Користувачем положень цього Договору, вимог законодавства України, а у випадках, визначених цим Договором, - вимог законодавства держави юрисдикції Користувача (залежно від того, що застосовно).</p>
  <p>8.8. У разі пред'явлення третіми особами будь-яких претензій до Сервісу, що виникли з вини Користувача, винна сторона зобов'язується співпрацювати з Сервісом в межах захисту Сервісу за такими претензіями та своїми силами і за свій рахунок врегулювати такі претензії з третіми особами, захистивши Сервіс від можливих спорів, збитків, відшкодувань шкоди, штрафних санкцій, інших додаткових витрат.</p>

  <h5>9. Інші умови.</h5>
  <p>9.1. Текст цього Договору є загальнодоступним. Цей Договір укладається на невизначений строк до моменту його розірвання сторонами і поширює свою дію на Користувачів, що здійснюють доступ до Сервісу та його використання, на дату початку такого доступу та використання.</p>
  <p>9.2. Користувач може розірвати цей Договір в будь-який час, видаливши обліковий запис (якщо існує) та припинивши користування Сервісом.</p>
  <p>9.3. Сервіс має право розірвати цей Договір в будь-який момент за власним розсудом шляхом розміщення відповідного повідомлення у Сервісі, надіслання повідомлення електронною поштою або іншими доступними засобами. У разі розірвання Сервісом цього Договору, Користувач втрачає доступ до Сервісу та свого Контенту.</p>
  <p>9.4. До цього Договору та відносин між Сервісом і Користувачем застосовується законодавство України. Питання, що не врегульовані цим Договором, підлягають вирішенню відповідно до законодавства України.</p>
  <p>9.5. Компанія, Користувач, Автор, Підписник несуть одноособову відповідальність за сплату всіх податків та зборів, що виникають у зв’язку із отриманням Донатів. Компанія не являється податковим агентом Користувача, Автора або Підписника щодо жодних податків та зборів, які стягуються на території України, відповідно до чинного законодавства України.</p>
  <p>9.6. Будь-які спори, що виникають з цього Договору або пов’язані з ним, вирішуються шляхом переговорів між сторонами. Якщо відповідний спір неможливо вирішити шляхом переговорів, він вирішується в судовому порядку за встановленою підвідомчістю та підсудністю такого спору відповідно до чинного законодавства України.</p>
  <p>9.7. Нездійснення Користувачем або Сервісом будь-яких прав, передбачених цим Договором, не є відмовою від таких прав.</p>
  <p>9.8. У разі, якщо будь-які з умов чи положень цього Договору будуть визнані судом або органом адміністративної влади компетентної юрисдикції недійсними, припиненими або такими, що не мають юридичної сили, це не призводить до недійсності чи позбавлення юридичної сили будь-яких інших частин Договору.</p>
  <p>9.9. У разі незгоди з будь-якими положеннями цього Договору або виникнення додаткових запитань чи в інших випадках, визначених цим Договором, Користувач можуть зв'язатися із Сервісом за адресами електронної пошти: busycast.com[at]gmail.com.</p>
  <p>9.10. Цей Договір укладений українською мовою, не вимагає двостороннього підписання та дійсний для сторін в електронному вигляді. У випадку перекладу цього Договору на інші мови та, як наслідок, виникнення різночитань перекладів текстів двох мов, перевагу має український текст Договору.</p>

</template>